import { createSlice } from "@reduxjs/toolkit";

const catalogSlice = createSlice({
	name: "catalogSlice",
	initialState: {
		multiSelect: false,
		twoCardsOnRow: false,
		statusCatalog: "main",
		catalogCards: [],
		selectedCards: [],
		lastSelectedIndex: null,
	},
	reducers: {
		setMultiSelect(state, action) {
			state.multiSelect = action.payload;
		},
		setTwoCardsOnRow(state, action) {
			state.twoCardsOnRow = action.payload;
		},
		setCatalogCards(state, action) {
			state.catalogCards = action.payload;
		},
		changeStatusCatalog(state, action) {
			state.statusCatalog = action.payload;
		},
		changeSelectedCard(state, action) {
			state.selectedCards = action.payload;
		},
		changeLastSelectedIndex(state, action) {
			state.lastSelectedIndex = action.payload;
		},
	},
});

export default catalogSlice.reducer;
export const {
	setMultiSelect,
	setTwoCardsOnRow,
	setCatalogCards,
	changeStatusCatalog,
	changeSelectedCard,
	changeLastSelectedIndex
} =
	catalogSlice.actions;
