import React from "react";
import "./footer.css";
import add_icon from "../../images/mobile/add.svg";
import search_icon_active from "../../images/mobile/search-icon-active.svg";
import profile from "../../images/profile/account_circle.svg";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { changeStateOpenPopupCreateCard } from "../../store/popupSlice";

const Footer = () => {
	const dispatch = useDispatch();
	const pageWidth = useSelector((state) => state.toolkit.pageWidth);
	return (
		pageWidth < 768 && (
			<div className="footer">
				<Link className="footer__frame">
					<img
						src={search_icon_active}
						alt="search_icon"
						className="header__search_icon"
					/>
					<h6>Поиск</h6>
				</Link>
				<div className="footer__frame">
					<button
						type="button"
						className="add"
						onClick={() =>
							dispatch(changeStateOpenPopupCreateCard(true))
						}>
						<img
							src={add_icon}
							alt="add_icon"
							className="add__icon"
						/>
					</button>
					<h6 className="footer__text">Добавить</h6>
				</div>
				<Link className="footer__frame" to="/account">
					<img src={profile} alt="profile_mobile_icon" />
					<h6>Профиль</h6>
				</Link>
			</div>
		)
	);
};

export default Footer;
