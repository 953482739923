import React from "react";
import "./createCardInput.css";
import arrow_drop_down from "../../../images/dropdown/arrow_drop_down.svg";
import arrow_drop_up from "../../../images/dropdown/arrow_drop_up.svg";
import Select from "react-dropdown-select";

const CreateCardInput = ({
  field,
  placeholder,
  dropdownData,
  fieldValues,
  handleChange,
  multi = true,
}) => (
  <Select
		create={field === "tags"}
		searchable={field === "tags"}
		className="createCardInput-select"
		options={
			field !== "brand_models"
				? dropdownData
				: dropdownData?.filter((el) => el.brand === fieldValues.brands[0]?.slug)
		}
		multi={multi}
		disabled={field === "brand_models" && !fieldValues.brands.length}
		valueField="id"
		labelField="title"
		values={fieldValues[field]}
		onChange={(values) => handleChange(field, values)}
		itemRenderer={({ item, methods }) => (
			<div className="select__element">
				{item.disabled ? (
					<div className="element">{item.title}</div>
				) : (
					<div onClick={() => methods.addItem(item)} className="element">
						<input
							className="custom-checkbox"
							type="checkbox"
							checked={methods.isSelected(item)}
							onChange={() => {}}
						/>
						<div className="element__text">
							{field === "colors" && item.hex_code ? (
								<div
									className="element__color"
									style={{ backgroundColor: item.hex_code }}
								/>
							) : null}
							<p>{item.title}</p>
						</div>
					</div>
				)}
			</div>
		)}
		dropdownHandleRenderer={({ state }) => (
			<img
				src={state.dropdown ? arrow_drop_up : arrow_drop_down}
				alt="dropdown icon"
			/>
		)}
		placeholder={placeholder}
	/>
);

export default CreateCardInput;
