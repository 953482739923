import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import CreateCardInput from "../../ui/inputs/createCardInput";
import InputFiles from "../../ui/inputs/inputFiles";
import { categoriesApi } from "../../api/categoriesService";
import { modelsApi } from "../../api/modelsService";
import { colorsApi } from "../../api/colorsService";
import { tagsApi } from "../../api/tagsService";
import { servicesApi } from "../../api/servicesService";
import { brandsApi } from "../../api/brandsService";
import ReturnText from "../../ui/inputs/returnText";
import { productsApi } from "../../api/productsService";
import {useFieldHandlers} from './useFieldHandlers';

export default function CreateCardInputs({
  onSetFile,
  cardInfo,
  modelsError,
  categoryError,
  filesError,
}) {
  const { data: categories } = categoriesApi.useGetCategoriesQuery();
  const { data: brands } = brandsApi.useGetBrandsQuery();
  const { data: brands_models } = modelsApi.useGetModelsQuery("");
  const { data: colors } = colorsApi.useGetColorsQuery();
  const { data: services } = servicesApi.useGetServicesQuery();
  const { data: tags } = tagsApi.useGetTagsQuery();
  const { data: product } = productsApi.useGetCurrentProductQuery(
    cardInfo?.slug
  );

  const dispatch = useDispatch();
  const { fieldValues, handleChange, setFieldValues } = useFieldHandlers(dispatch);

  useEffect(() => {
    if (product) {
      setFieldValues({
        brands: brands?.filter((b) => b.slug === product.brand_models[0]?.brand) || [],
        brand_models: product.brand_models || [],
        categories: product.categories || [],
        colors: product.colors || [],
        services: product.services || [],
        tags: product.tags || [],
      });
    }
  }, [product, brands, setFieldValues]);

  return (
    <div className="createCardPopup__inputs">
      {cardInfo?.status === "ON_REWORK" && <ReturnText cardInfo={cardInfo} />}
      <div className="createCardPopup__inputs-containers">
        <h4 className="newCardClear__input-box-text">Марка авто</h4>
        <CreateCardInput
          field="brands"
          placeholder="Выберите марку"
          dropdownData={brands}
          fieldValues={fieldValues}
          handleChange={handleChange}
          multi={false}
        />
      </div>
      <div className="createCardPopup__inputs-containers">
        <h4 className="newCardClear__input-box-text">Модель авто</h4>
				<div className="newCardClear_input_block">
					<CreateCardInput
						field="brand_models"
						placeholder="Выберите модель"
						dropdownData={brands_models}
						fieldValues={fieldValues}
						handleChange={handleChange}
					/>
					{modelsError && (
						<span className="newCardClear__input_error">
							Это поле не может быть пустым
						</span>
					)}
				</div>
      </div>
      <div className="createCardPopup__inputs-containers">
        <h4 className="newCardClear__input-box-text">Категория</h4>
				<div className="newCardClear_input_block">
					<CreateCardInput
						field="categories"
						placeholder="Выберите категорию"
						dropdownData={categories}
						fieldValues={fieldValues}
						handleChange={handleChange}
					/>
					{categoryError && (
						<span className="newCardClear__input_error">
							Это поле не может быть пустым
						</span>
					)}
				</div>
      </div>
      <div className="createCardPopup__inputs-containers">
        <h4 className="newCardClear__input-box-text">Цвет</h4>
        <CreateCardInput
          field="colors"
          placeholder="Введите название цвета"
          dropdownData={colors}
          fieldValues={fieldValues}
          handleChange={handleChange}
        />
      </div>
      <div className="createCardPopup__inputs-containers">
        <h4 className="newCardClear__input-box-text">Теги</h4>
        <CreateCardInput
          field="tags"
          placeholder="Введите тег"
          dropdownData={tags}
          fieldValues={fieldValues}
          handleChange={handleChange}
        />
      </div>
      <div className="createCardPopup__inputs-containers">
        <h4 className="newCardClear__input-box-text">Услуги</h4>
        <CreateCardInput
          field="services"
          placeholder="Выберите услугу"
          dropdownData={services}
          fieldValues={fieldValues}
          handleChange={handleChange}
        />
      </div>
      <InputFiles onSetFile={onSetFile} filesError={filesError} />
    </div>
  );
}
