import React, { useEffect, forwardRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	changeStateOpenPopup,
	changeStateOpenPopupActions,
	changeStateOpenPopupChangeNameCategory,
	changeStateOpenPopupCreateCard,
} from "../../../store/popupSlice";
import edit from "../../../images/profile/edit.svg";
import del from "../../../images/profile/delete.svg";
import "./actions.css";
import { brandsApi } from "../../api/brandsService";
import { categoriesApi } from "../../api/categoriesService";
import { modelsApi } from "../../api/modelsService";
import { colorsApi } from "../../api/colorsService";
import { servicesApi } from "../../api/servicesService";
import { tagsApi } from "../../api/tagsService";
import { productsApi } from "../../api/productsService";
import { changeStatePreloader } from "../../../store/toolkitSlice";
import "./actions.css";

const ActionsBtns = forwardRef((props, ref) => {
	const dispatch = useDispatch();
	const openPopupActions = useSelector(
		(state) => state.popup.openPopupActions
	);
	const slug = useSelector((state) => state.toolkit.slug);
	const openFolder = useSelector((state) => state.account.openFolder);
	const isOpenPopup = useSelector((state) => state.popup.openPopup);
	const cardInfo = useSelector((state) => state.popup.cardInfo);
	const user = useSelector((state) => state.auth.userData);

	const [
		deleteBrands,
		{
			data: successDeleteBrands,
			error: ErrorDeleteBrands,
			isLoading: LoadingDeleteBrands,
		},
	] = brandsApi.useDeleteBrandsMutation();
	const [
		deleteModels,
		{
			data: successDeleteModels,
			error: ErrorDeleteModels,
			isLoading: LoadingDeleteModels,
		},
	] = modelsApi.useDeleteModelsMutation();
	const [
		deleteCategories,
		{
			data: successDeleteCategories,
			error: ErrorDeleteCategories,
			isLoading: LoadingDeleteCategories,
		},
	] = categoriesApi.useDeleteCategoriesMutation();
	const [
		deleteColors,
		{
			data: successDeleteColors,
			error: ErrorDeleteColors,
			isLoading: LoadingDeleteColors,
		},
	] = colorsApi.useDeleteColorsMutation();
	const [
		deleteServices,
		{
			data: successDeleteServices,
			error: ErrorDeleteServices,
			isLoading: LoadingDeleteServices,
		},
	] = servicesApi.useDeleteServicesMutation();
	const [
		deleteTags,
		{
			data: successDeleteTags,
			error: ErrorDeleteTags,
			isLoading: LoadingDeleteTags,
		},
	] = tagsApi.useDeleteTagsMutation();
	const [
		deleteProducts,
		{ error: ErrorDeleteProducts, isLoading: LoadingDeleteProducts },
	] = productsApi.useDeleteProductsMutation();

	useEffect(() => {
		if (
			LoadingDeleteBrands === true ||
			LoadingDeleteModels === true ||
			LoadingDeleteCategories === true ||
			LoadingDeleteColors === true ||
			LoadingDeleteServices === true ||
			LoadingDeleteTags === true ||
			LoadingDeleteProducts === true
		) {
			dispatch(changeStatePreloader(true));
		} else {
			dispatch(changeStatePreloader(false));
		}
	}, [
		LoadingDeleteBrands,
		LoadingDeleteModels,
		LoadingDeleteCategories,
		LoadingDeleteColors,
		LoadingDeleteServices,
		LoadingDeleteTags,
		LoadingDeleteProducts,
	]);
	async function HandleDeleteProduct() {
		dispatch(changeStateOpenPopupActions(""));
		dispatch(changeStateOpenPopup(false));
		await deleteProducts(cardInfo.slug);
	}
	async function HandleDelete() {
		dispatch(changeStateOpenPopupActions(""));
		switch (openFolder) {
			case "Марка авто":
				await deleteBrands(slug);
				break;
			case "Модель авто":
				await deleteModels(slug);
				break;
			case "Категории":
				await deleteCategories(slug);
				break;
			case "Цвет":
				await deleteColors(slug);
				break;
			case "Услуги":
				await deleteServices(slug);
				break;
			case "Продукты":
				await deleteProducts(slug);
				break;
			case "Тэги":
				await deleteTags(slug);
				break;
			default:
				break;
		}
	}
	function HandlePatch() {
		dispatch(changeStateOpenPopupActions(""));
		dispatch(changeStateOpenPopupChangeNameCategory(true));
	}
	function HandlePatchCard() {
		dispatch(changeStateOpenPopupActions("put"));
		dispatch(changeStateOpenPopup(false));
		dispatch(changeStateOpenPopupCreateCard(true));
	}

	return (
		openPopupActions !== "" && (
			<div
				ref={ref}
				className={
					!isOpenPopup
						? "actions__buttons"
						: "actions__buttons actions__buttons_popup"
				}>
				{!isOpenPopup ? (
					<button
						className="action__button"
						type="button"
						onClick={HandlePatch}>
						<img src={edit} alt="edit" />
						<p>Переименовать</p>
					</button>
				) : (
					user?.is_admin && (
						<button
							className="action__button"
							type="button"
							onClick={HandlePatchCard}>
							<img src={edit} alt="edit" />
							<p>Редактировать</p>
						</button>
					)
				)}
				{user?.is_admin && (
					<button
						className="action__button"
						type="button"
						onClick={
							isOpenPopup ? HandleDeleteProduct : HandleDelete
						}>
						<img src={del} alt="edit" />
						<p className="action__button-text">Удалить</p>
					</button>
				)}
			</div>
		)
	);
});

export default ActionsBtns;

