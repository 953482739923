import React, { useEffect, useState } from "react";
import Filter from "../filters/filter/filter";
import "./catalog.css";
import Card from "../card/card";
import { useDispatch, useSelector } from "react-redux";
import { productsApi } from "../api/productsService";
import Tools from "./tools";
import { changeStatePreloader } from "../../store/toolkitSlice";
import { setCatalogCards } from "../../store/catalogSlice";

const Catalog = () => {
	const dispatch = useDispatch();
	const [requestFilters, setRequestFilters] = useState("");
	const {
		filtersModel, 
		filtersSorter,
		filtersCategory,
		filtersBrand,
		filtersService,
		filtersColor,
		filtersSearch,
		filtersTag,
		filterChecked,
	} = useSelector((state) => state.filters);
	const {
		data: cards,
		isLoading: isLoadingCards,
		isFetching: isFetchingCards,
		// refetch,
	} = productsApi.useGetProductsQuery(requestFilters ? requestFilters : "");
	const [limit, setLimit] = useState(12);
	const [fetching, setFetching] = useState(false);
	const [offset, setOffset] = useState(0);
	const fileType = useSelector((state) => state.filters.fileType);
	const pageWidth = useSelector((state) => state.toolkit.pageWidth);
	const {twoCardsOnRow} = useSelector((state) => state.catalog);
	const filters = [
		...filtersCategory,
		...filtersModel,
		...filtersBrand,
		...filtersService,
		...filtersColor,
		...filtersTag,
	];

	let colors = "";
	let brands = "";
	let models = "";
	let services = "";
	let categories = "";
	let tags = "";

	useEffect(() => {
		document.addEventListener("scroll", scrollHandler);
		return function () {
			document.removeEventListener("scroll", scrollHandler);
		};
	}, []);

	const scrollHandler = (e) => {
		if (
			e.target.documentElement.scrollHeight -
				(e.target.documentElement.scrollTop + window.innerHeight) <
			100
		) {
			setFetching(true);
		}
	};

	useEffect(() => {
		if(fetching && limit < cards?.count) {
			setLimit(limit + 12);
		}
	}, [fetching]);

	useEffect(() => {
		if (isFetchingCards || isLoadingCards) {
			dispatch(changeStatePreloader(true));
		} else {
			dispatch(changeStatePreloader(false));
		}
		setFetching(false);
	}, [isFetchingCards, isLoadingCards, dispatch]);

	useEffect(() => {
		filtersCategory.forEach((filter) => {
			categories = categories.concat("&categories=", `${filter.slug}`);
		});
		filtersBrand.forEach((filter) => {
			brands = brands.concat("&brands=", `${filter.slug}`);
		});
		filtersModel.forEach((filter) => {
			models = models.concat("&brand_models=", `${filter.slug}`);
		});
		filtersService.forEach((filter) => {
			services = services.concat("&services=", `${filter.slug}`);
		});
		filtersColor.forEach((filter) => {
			colors = colors.concat("&colors=", `${filter.slug}`);
		});
		filtersTag.forEach((filter) => {
			tags = tags.concat("&tags=", `${filter.slug}`);
		});

		const newFilterString = categories.concat(
			brands,
			models,
			services,
			colors,
			tags,
			filtersSearch,
			filtersSorter,
			"&limit=",
			limit,
			"&file_type=",
			fileType,
			"&offset=",
			offset
		)
		setRequestFilters(newFilterString);
	}, [
		filtersBrand,
		filtersColor,
		filtersCategory,
		filtersService,
		filtersModel,
		filtersSorter,
		filtersTag,
		filtersSearch,
		filterChecked,
		offset,
		limit,
	]);

	useEffect(() => {
		dispatch(setCatalogCards(cards?.results))
	}, [cards])

	function HandleResetFilters() {
		setRequestFilters("");
	}

	return (
		<div className="catalog">
			{pageWidth > 767 && filters.length !== 0 && (
				<ul className="catalog__filters">
					{filters.map((filter) => (
						<div key={filter.id}>
							<Filter filter={filter} role={"del"} />
						</div>
					))}
					<Filter
						filter="Сбросить все"
						role={"reset"}
						HandleResetFilters={HandleResetFilters}
					/>
				</ul>
			)}
			<div className="catalog__tools">
				<Tools cards={cards} />
			</div>
			<div className={twoCardsOnRow ? "cards grid" : "cards"}>
				{cards?.results?.map((card) => (
					<Card key={card.id} cardInfo={card} />
				))}
			</div>
		</div>
	);
};
export default Catalog;
