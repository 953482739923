import React, { useEffect } from "react";
import "./myProducts.css";
import { userApi } from "../../../components/api/userService";
import Card from "../../../components/card/card";
import { useDispatch } from "react-redux";
import { changeStatePreloader } from "../../../store/toolkitSlice";

export default function MyProducts({ id }) {
	const dispatch = useDispatch();
	const { data: products, isFetching: isFetchingCards } =
		userApi.useGetUserProductsQuery(id);
	useEffect(() => {
		if (isFetchingCards === true) {
			dispatch(changeStatePreloader(true));
		} else {
			dispatch(changeStatePreloader(false));
		}
	}, [isFetchingCards]);
	return (
		<div>
			<h1>Мои фото</h1>
			<div className="my_products">
				{products?.map((card) => (
					<Card key={card.id} cardInfo={card} />
				))}
			</div>
		</div>
	);
}
