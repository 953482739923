import React, { useEffect, useState } from "react";
import "./toggle.css";
import { useDispatch, useSelector } from "react-redux";
import { setFileType, setFilterChecked } from "../../../store/filtersSlice";

export default function Toggle({ all }) {
	const filterChecked = useSelector((state) => state.filters.filterChecked);
	const [checked, setChecked] = useState(filterChecked);
	const dispatch = useDispatch();

	function HandleChecked() {
		setChecked(!checked);
	}

	useEffect(() => {
		if (checked) {
			dispatch(setFileType("Video"));
			dispatch(setFilterChecked(true));
		} else {
			dispatch(setFileType(""));
			dispatch(setFilterChecked(false));
		}
	}, [checked]);

	return (
		<div className="toggle-container all-filters">
			<p className={all === "all" ? "all-text" : ""}>Только с видео</p>
			<input
				checked={filterChecked}
				type="checkbox"
				id="toggle-btn"
				onChange={HandleChecked}
			/>
			<label htmlFor="toggle-btn" className="toggle-label"></label>
		</div>
	);
}
