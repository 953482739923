import { createSlice } from "@reduxjs/toolkit";

const filtersSlice = createSlice({
	name: "filtersSlice",
	initialState: {
		filtersCategory: [],
		filtersModel: [],
		filtersBrand: [],
		filtersService: [],
		filtersColor: [],
		filtersTag: [],
		filtersSorter: "",
		filtersSearch: "",
		fileType: "",
		filterChecked: false,
	},
	reducers: {
		setFiltersCategory(state, action) {
			state.filtersCategory = action.payload;
		},
		setFiltersModel(state, action) {
			state.filtersModel = action.payload;
		},
		setFiltersBrand(state, action) {
			state.filtersBrand = action.payload;
		},
		setFiltersService(state, action) {
			state.filtersService = action.payload;
		},
		setFiltersColor(state, action) {
			state.filtersColor = action.payload;
		},
		setFiltersSorter(state, action) {
			state.filtersSorter = action.payload;
		},
		setFiltersTag(state, action) {
			state.filtersTag = action.payload;
		},
		setFiltersSearch(state, action) {
			state.filtersSearch = action.payload;
		},
		setFileType(state, action) {
			state.fileType = action.payload;
		},
		setFilterChecked(state, action) {
			state.filterChecked = action.payload;
		},
	},
});

export default filtersSlice.reducer;
export const {
	setFiltersCategory,
	setFiltersBrand,
	setFiltersColor,
	setFiltersModel,
	setFiltersService,
	setFiltersSorter,
	setFiltersTag,
	setFiltersSearch,
	setFileType,
	setFilterChecked,
} = filtersSlice.actions;
