import { useCallback, useState } from "react";
import {
  setBrand_models,
  setBrands,
  setCategories,
  setColors,
  setServices,
  setTags,
} from "../../../store/newProductDataSlice";

export const useFieldHandlers = (dispatch) => {
  const [fieldValues, setFieldValues] = useState({
    brands: [],
    brand_models: [],
    categories: [],
    colors: [],
    services: [],
    tags: [],
  });

  const handleChange = useCallback(
    (field, values) => {
      setFieldValues((prev) => {
        const newFieldValues = { ...prev, [field]: values };
  
        if (field === "brands") {
          newFieldValues.brand_models = [];
        }
  
        return newFieldValues;
      });

      const data = values.map((item) => item.slug || item.title);

      switch (field) {
        case "brands":
          dispatch(setBrands(values));
          dispatch(setBrand_models([]));
          break;
        case "brand_models":
          dispatch(setBrand_models(data));
          break;
        case "categories":
          dispatch(setCategories(data));
          break;
        case "colors":
          dispatch(setColors(data));
          break;
        case "services":
          dispatch(setServices(data));
          break;
        case "tags":
          dispatch(setTags(data));
          break;
        default:
          break;
      }
    },
    [dispatch]
  );

  return { fieldValues, handleChange, setFieldValues };
};