import React from "react";
import Filter from "../filters/filter/filter";
import "./popup.css";

export default function PopupCardInfo({ cardPopupInfo, articles }) {
	return (
		<div className="popup__about">
			<div className="popup__about-block">
				<p className="popup__about-category">Артикул</p>
				<div className="popup__map_container">
					{articles.map((article, index) => (
						<span key={article.id}>
							<span className="popup__text">{article.title}</span>
							{index < articles.length - 1 && (
								<span className="popup__dot">&nbsp;&#183;&nbsp;</span>
							)}
						</span>
					))}
				</div>
			</div>
			<div className="popup__about-block">
				<p className="popup__about-category">Категория</p>
				<div className="popup__map_container">
					{cardPopupInfo?.categories.map((category, index) => (
						<span key={category.id}>
							<span className="popup__text">{category.title}</span>
							{index < cardPopupInfo.categories.length - 1 && (
								<span className="popup__dot">&nbsp;&#183;&nbsp;</span>
							)}
						</span>
					))}
				</div>
			</div>
			<div className="popup__about-block">
				<p className="popup__about-category">Марка авто</p>
				<div className="popup__map_container">
					<span className="popup__text">{cardPopupInfo?.brand_models[0]?.brand}</span>
				</div>
			</div>
			<div className="popup__about-block">
				<p className="popup__about-category">Модель авто</p>
				<div className="popup__map_container">
					{cardPopupInfo?.brand_models.map((brand_model, index) => (
						<span key={brand_model.title}>
							<span className="popup__text">{brand_model.title}</span>
							{index < cardPopupInfo.brand_models.length - 1 && (
								<span className="popup__dot">&nbsp;&#183;&nbsp;</span>
							)}
						</span>
					))}
				</div>
			</div>
			<div className="popup__about-block">
				<p className="popup__about-category">Услуги</p>
				<div className="popup__map_container">
					{cardPopupInfo?.services.map((service, index) => (
						<span key={service.id}>
							<span className="popup__text">{service.title}</span>
							{index < cardPopupInfo.services.length - 1 && (
								<span className="popup__dot">&nbsp;&#183;&nbsp;</span>
							)}
						</span>
					))}
				</div>
			</div>
			<div className="popup__about-block">
				<p className="popup__about-category">Цвет</p>
				<div className="popup__map_container">
					{cardPopupInfo?.colors.map((color, index) => (
						<span key={color.id}>
							<span className="popup__text">{color.title}</span>
							{index < cardPopupInfo.colors.length - 1 && (
								<span className="popup__dot">&nbsp;&#183;&nbsp;</span>
							)}
						</span>
					))}
				</div>
			</div>
			<div className="popup__about-block">
				<p className="popup__about-category">Тэги</p>
				{cardPopupInfo?.tags && (
					<ul className="popup__tags">
						{cardPopupInfo?.tags.map((tag) => (
							<Filter key={tag.id} filter={tag} role={"card-tag"} />
						))}
					</ul>
				)}
			</div>
		</div>
	);
}
